import React, { useState, useRef, useEffect } from 'react';
import styles from './w.module.scss';
import Button from '../button';
import Checkbox from '../checkbox';
import Rand from '../rand';
import Chance from '../chance';
import ToggleSwitch from '../switch';
import { Howl } from 'howler';
import { Wheel } from 'react-custom-roulette'

const WheelOfFortune = () => {
  const soundRef                                          = useRef();
  const buttonRef                                         = useRef();
  const lastBets                                          = useRef()
  const [ mustSpin, setMustSpin ]                         = useState( false )
  const [ data, setData ]                                 = useState([{option:""}])
  const [ winID, setWinID ]                               = useState(-1);
  const [ text, setText ]                                 = useState('')
  const [ fastSpin, setFastSpin ]                         = useState( true );
  const [ deleteWin, setDeleteWin ]                       = useState( true )
  const [ lastWon, setLastWon ]                           = useState( null )
  const [ load, setLoad ]                                 = useState( false );
  const [ speed, setSpeed ]                               = useState(4.2)
  const [ soundActive, setSoundActive ]                   = useState( false )
  const [ pointerStyle, setPointerStyle ]                 = useState( false );
  const [ lastMultipleWon, setLastMultipleWon ]           = useState([])
  const [ isMultipleSpin, setIsMultipleSpin ]             = useState( false );
  const [ multipleSpinCount, setMultipleSpinCount ]       = useState( 5 )

  const [ settings, setSettings ] = useState( {
    radiusLineWidth: 1,
    fontSize: 12,
    outerBorderWidth: 2,
    textDistance: 60,
    textColors: ['#ffffff'],
    fontFamily: 'Roboto'
  } )



  useEffect( () => {
    if( !load ) {
      addMultipleNothing( 5, "NIC" )
      addMultipleNothing( 1, "Zdrady" )
      setLoad( true )
    }
},[ load ] )

  useEffect( () => {
    if( text.split('\n')[0] == '' && data.length == 0) {
      setData( [{ option: "" }] )
    }
    if( text.length > 0 ) {
      convertText( text )
    }
  },[ text ] )


  useEffect(() => {
    if (data.length === 0) {
      setData([{ option: "" }]);
    } else if (data.length > 60) {
      setSettings(prevSettings => ({
        ...prevSettings,
        fontSize: 8, 
        radiusLineWidth: 0.1,
        textDistance: 85, 
      }));
    } else if (data.length > 30) {
      setSettings(prevSettings => ({
        ...prevSettings,
        fontSize: 10,
        radiusLineWidth: 1,
        textDistance: 80,
      }));
    } else {
      setSettings(prevSettings => ({
        ...prevSettings,
        fontSize: 12,
        radiusLineWidth: 1,
        textDistance: 50, 
      }));
    }
  }, [data]);

  useEffect( () => {
    if( soundActive ) {
      stopSound()
      playSound('/spin1.mp3')
    }
  },[ soundActive ] )

  const createOption = ( val, index ) => {
    const hue = (index * 137.508) % 360;
    const saturation = 70 + (index % 5) * 5;
    const lightness = 50 + (index % 3) * 10;
    const backgroundColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    let temp = { id: index, option: val, style: { backgroundColor: backgroundColor, textColor: 'black' } }
    return temp;
  }



  const convertText = ( text ) => {
    let arr = text.split('\n')
    let tempArr = [];
    if( arr.length > 0 ) {
      arr.forEach((el, i) => {
        if( el !== "") {
          tempArr.push( createOption( el, i ) )
        }
      });
    }
    setData( tempArr )
  }


  const wait = ( ms ) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

  const handleSpinClick = async() => {
    if (!mustSpin) {
      let rand
      rand = Math.floor(Math.random() * data.length);
      console.log( 'rand', rand )
      setWinID(rand);
      setMustSpin(true);
      setSoundActive( true )
      if( isMultipleSpin ) {
        await waitForEndSpin();
      } else {
        await wait( fastSpin ? 700 : 3600 )
      }
          let arr = [ ...lastMultipleWon ];
          arr.push( data[rand] );
          setLastMultipleWon( arr )
    }
  }

  useEffect( () => {
    console.log( lastMultipleWon )
  },[ lastMultipleWon ] )

  const addMultipleNothing = (count, word) => {
    
    let temp =  `\n${ word }\n` 
    for (let index = 0; index < count - 1; index++) {
      if( index != count - 1 ) {
        temp += `${ word }\n`
      } else {
        temp += `${ word }`
      }
    }
    setText( prev => prev + temp )
  }

  const stopWheele = async() => {
    await wait( 400 )
    setMustSpin(false)
    setLastWon( data[winID] )

    if( data.length >= 2 && deleteWin ) {
       deleteWonOption( winID )
    }
  }

  const deleteWonOption = () => {
    const temp = [...data];
    if (winID >= 0 && winID < temp.length) {
        const newData = temp.filter((_, index) => index !== winID);
        setData(newData);
    }
  }

  const playSound = ( src ) => {
    const sound = new Howl({
      src: [src],
      rate: speed, // Ustawienie prędkości odtwarzania
      onend: () => {
        setSoundActive(false);
      }
    });

    soundRef.current = sound;
    sound.play();
    setSoundActive(true);
  };

  const stopSound = () => {
    if (soundRef.current) {
      soundRef.current.stop();
      setSoundActive(false);
    }
  };

  const changeFastSpin = ( val ) => {
    setFastSpin( val )
    setSpeed( val ? 4.2 : 1.2 )
  }

  const multipleSpin = async() => {
    setIsMultipleSpin( true )
    let count = multipleSpinCount;
      for (let index = 0; index < count; index++) {
        await wait( 800 )
        spinSingle( count == index + 1 )
        await waitForEndSpin()
      }
  }

  const waitForEndSpin = async() => {
    await wait( fastSpin ? 1500 : 3600 )
  }

  const spinSingle = async( isLast ) => {
      setMultipleSpinCount( prev => prev - 1 )
      buttonRef.current.click()
  
      if( isLast ) {
        await stopMultiple()
      }
  }

  const stopMultiple = async() => {
    await wait( 500 )
    setIsMultipleSpin( false )
    setMultipleSpinCount(1)
  }


  const scrollToBottom = () => {
    if (lastBets.current) {
      console.log('click')
      lastBets.current.scrollTop = lastBets.current.scrollHeight - lastBets.current.clientHeight;
    }
  };

  const isDisabled = () => {
    if( mustSpin || isMultipleSpin ) {
      return true
    }

    return false
  }

  useEffect( () => {
    if( lastMultipleWon.length > 6 ) {
      scrollToBottom()
    }
  },[ lastMultipleWon ] )

  return (
    <div className={styles.wheel_of_fortune}  >
      <div className={styles.wheel_wrapper}>
        <div className={ styles.left_cols_wrapper }>
        <div className={ styles.left_col }>
          <div className={styles.chance_w_box}>
                < Chance data={data} />
          </div>
        </div>
        <div className={ styles.left_col }>
          <div className={ styles.multiple_spin }>
              <div className={ styles.title } onClick={ () => scrollToBottom() }>
                <h5>Multiple spin</h5>
              </div>
              <div className={ `${ styles.multiple_box } ${ isDisabled() ? styles.disabled : "" }` }>
                <div className={ styles.mc_input }>
                  <input  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          type="number"
                          value={ multipleSpinCount }
                          onChange={ (e) => setMultipleSpinCount( e.target.value ) } />
                </div>
                { ( multipleSpinCount && multipleSpinCount <= data.length) ?
                  <div className={ styles.button }>
                    < Button small title={ isMultipleSpin ? `Zostało ${ multipleSpinCount  }` : ` Losuj x${ multipleSpinCount } `} isRed = { isMultipleSpin } setFunction={ () =>  isMultipleSpin ? "" : multipleSpin() } />
                  </div>
                  :
                  <div className={ styles.error }>
                    <p>Popraw dane</p>
                  </div>
                }
              </div>
          </div>
        </div>
        <div className={ styles.left_col }>
              <div className={ styles.title }>
                <h5>Wylosowane:</h5>
              </div>
            <div className={ styles.last_multiple } ref={ lastBets } >
              { lastMultipleWon.map( ( el, i ) => (
                <div className={ styles.last_m } style={{ backgroundColor: el?.style?.backgroundColor }} >
                  <p>{ el.option }</p>
                </div>
              ) ) }
            </div>
        </div>
        </div>
        <div className={styles.wheel}>
          <div className={styles.button_start}>
            <div className={ ` ${ styles.button_rand } ${ isDisabled() ? styles.disabled : "" } ` }>
              <div ref={ buttonRef } onClick={ () => handleSpinClick() } >losuj</div>
            </div>
            {/* < Button title={"losuj"} setFunction={() => handleSpinClick()} /> */}
          </div>
          <div className={styles.wheel_container}>
            {data && data.length > 0 &&
              <div className={styles.wheele_box}>
                <Wheel
                      mustStartSpinning={mustSpin}
                      prizeNumber={winID ? winID : 0}
                      data={data}
                      pointerProps={ pointerStyle ? {src:'/pointer.svg'} : "" }
                      radiusLineWidth={settings.radiusLineWidth}
                      spinDuration={ fastSpin ? 0.05 : 0.3 }
                      fontSize={settings.fontSize}
                      textDistance={ settings.textDistance }
                      onStopSpinning={ () => stopWheele() }
                      fontFamily={settings.fontFamily}
                      outerBorderWidth={settings.outerBorderWidth}
                      textColors={settings.textColors}
                />
                
              </div>
            }
          </div>
     

            <div className={styles.last_won_wrapper}>
              { lastWon && lastWon.style &&
                <p>Ostatnia wygrana:</p>
              }
              { lastWon && lastWon.style &&
                <div className={styles.last_won} style={{ backgroundColor: lastWon?.style?.backgroundColor }} >
                  <p>{lastWon.option}</p>
                </div>
              }
            </div>
          

        </div>
        <div className={styles.options_text_wrapper}>
          <div className={styles.cols}>
              <div className={`${styles.add_not} ${ isDisabled() ? styles.disabled : ""}`}>
                <div className={styles.button_c}>
                  < Button title="nic x10" setFunction={() => addMultipleNothing(10, "NIC")} />
                </div>
                <div className={styles.button_c}>
                  < Button title="nic x20" setFunction={() => addMultipleNothing(20, "NIC")} />
                </div>
                <div className={styles.button_c}>
                  < Button title="nic x50" setFunction={() => addMultipleNothing(50, "NIC")} />
                </div>
              </div>
            <div className={styles.col}>
              <div className={` ${styles.text} ${ isDisabled() ? styles.disabled : ""} `}>
                <div className={styles.title}>
                  <p>Wypisz opcje losowania</p>
                </div>
                <textarea name="text"
                  value={text}
                  onChange={(e) => setText(e.target.value)} />
              </div>


            </div>

            <div className={`${styles.checkbox_container_wrapper} ${ isDisabled() ? styles.disabled : ""}`}>
              <div className={styles.checkbox_container} >
                < ToggleSwitch title="Szybkie losowanie"
                  setFunction={ (val) => changeFastSpin(val) }
                  small
                  data={[{ id: true, name: "ON" }, { id: false, name: "OFF" }]} />
              </div>
              <div className={styles.checkbox_container} >
            
                < ToggleSwitch title="Usuń wylosowany"
                  setFunction={(val) => setDeleteWin(val)}
                  small
                  data={[{ id: true, name: "ON" }, { id: false, name: "OFF" }]} />
              </div>
            </div>

          </div>
        </div>
           
      </div>
    </div>
  );
};

export default WheelOfFortune;
