import { useEffect, useState } from "react";
import styles from './c.module.scss'
const Chance = ({ data }) => {

    const [ boxes, setBoxes ] = useState([]);

    useEffect( () => {
        if( data ) {
            getChance( data )
        }
    },[ data ] )

    function getChance(array) {
        const counters = {};
        const totalElements = array.length;
        array.forEach(item => {
            const element = item.option.toLowerCase();
            if (counters[element]) {
                counters[element]++;
            } else {
                counters[element] = 1;
            }
        });
    
        const result = Object.keys(counters).map(key => ({
            name: key,
            count: counters[key],
            chance: ((counters[key] / totalElements) * 100).toFixed(2)
        }));
    
        result.sort((a, b) => b.chance - a.chance);
        setBoxes(result);
    }

    const convertLong = ( str ) => {
        if( str.length > 6 ) {
            return str.substring(0, 6) + "..."
        } else {
            return str
        }
    }    

    return (
        <div className={ styles.chance } onClick={ () => getChance( data ) } >
            { boxes && boxes.map( ( el, i ) => (
                <div className={ styles.chance_box }>
                    <small>{ i + 1 }.</small>
                    <div className={ styles.cb_name }>
                        <p>{ convertLong( el.name ) } <small>( { el.count } )</small> </p>
                    </div>
                    <div className={ styles.chance_bar }>
                        <div className={ styles.pr_bar } style={ { width: `${ el.chance }%` } } />
                        <p> { el.chance }%</p>
                    </div>
                </div>
            ) ) }
        </div>
    )
}

export default Chance;