import { useEffect, useState } from 'react';
import styles from './ts.module.scss';

const ToggleSwitch = ({ data, setFunction, defaultValue, title, small}) => {

    const [ active, setActive ] = useState( 0 );
    const [ load, setLoad ] = useState(false);
   

    useEffect( () => {
        if( !load ) {
            setLoad( load )
            if( defaultValue ) {
                setActive( defaultValue )
            }
        }
    },[ load ] )


    useEffect( () => {
        if( data[active] ) {
            setFunction(data[active].id)
        }
    },[ active ] )

    return (
        <>
            { ( data && data.length > 0 ) &&
                <div className={ ` ${ styles.toggle_switch } ${ data[active].name == "OFF" ? styles.off : "" } ${ small ? styles.small : "" } ` }  >
                    { title &&    
                        <div className={ styles.title }>
                            <h5>{ title }</h5>
                        </div>
                    }
                    <div className={ styles.switch }>
                        { data.map( ( option, i ) => (
                            <div style={ { width: `${ 100 / data.length }%` } } className={ `${ styles.option  } ${ active === i ? styles.active_bar : "" }` } onClick={ () => setActive( i ) } >
                                <p>{ option.name }</p>
                            </div>                
                        ) ) }
                        <div style={ { width: `${ 100 / data.length }%`, transform: `translateX(${ 100 * active  }%)` } } className={ styles.active }/>
                    </div>
                    { data[active]?.shorDesc &&
                        <div className={ styles.message }>
                            <p>{ data[active]?.shorDesc }</p>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default ToggleSwitch;