import Footer from './../src/components/footer';
import WheelOfFortune from './../src/components/wheele';
import Rand from './components/rand';
function App() {
  return (
    <>
      <WheelOfFortune />
      < Rand />
      <Footer />
    </>
  );
}

export default App;
