import styles from './b.module.scss';

const Button = ({ title, setFunction, ref, small }) => {
    return (
        <div className={ ` ${ styles.button } ${ small ? styles.small : "" } ` } onClick={ () => setFunction() }>
            <p>{ title }</p>
        </div>
    )
}

export default Button;