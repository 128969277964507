import { useState } from "react";
import Button from "../button";
import styles from './r.module.scss'
const Rand = () => {

    const [ random, setRandom ] = useState( null );
    const [ min, setMin ] = useState( 0 );
    const [ max, setMax ] = useState( 0 );

    const getRandom = ( ) => {
        if( random ) {
            setRandom(false)
        }
        if( +min < +max ) {
            let fMin = Math.ceil(min);    
            let fMax = Math.floor(max);  
            let rand = Math.floor(Math.random() * (fMax - fMin + 1)) + fMin
            setRandom( rand ) 
        } else {
            setRandom('Popraw dane')
        }
    }


    return (
        <div className={ styles.random_number }>
            <div className={ styles.r_n_title }>
                <p>Losowa liczba</p>
            </div>
            <div className={ styles.r_n_cols_wrapper }>
                <div className={ styles.inputs }>
                    <div className={ styles.r_n_col }>
                        <p>MIN</p>
                        <input onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} type="number" onChange={ ( e ) => setMin( e.target.value ) }/>
                    </div>
                    <div className={ styles.r_n_col }>
                        <p>MAX</p>
                        <input onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} type="number" onChange={ ( e ) => setMax( e.target.value ) }/>
                    </div>
                </div>
                <div className={ styles.r_n_col }>
                    < Button title="generuj" setFunction={ () => getRandom( ) }/>
                </div>
                { random &&
                    <div className={ styles.random_result }>
                        <p>{ random }</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default Rand;