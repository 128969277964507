import { ReactComponent as IgIco} from './../../images/ig.svg';
import  { ReactComponent as KickLogo}  from './../../images/kick.svg';
import styles from './f.module.scss'

const Footer = () => {

    return (
        <footer>
            <div className={ styles.footer_wrapper }>
                <div className={ styles.socials }>
                    <div className={ styles.social_col }>
                        <a target='_blank' href="https://www.instagram.com/pawlosjacek/">
                            < IgIco />
                        </a>
                    </div>
                    <div className={ styles.social_col }>
                        <a target='_blank' href="https://kick.com/denwer">
                            < KickLogo />
                        </a>
                    </div>
                </div>
                <div className={ styles.date }>
                    <small>denwer { new Date().getFullYear() } 	&#169;</small>
                </div>
            </div>
        </footer>
    )
}

export default Footer;